.title {
    margin:0;
    font-weight: 300;
    padding: 20px
}

.header {
    display:flex;
    box-shadow: 0px 15px 7px rgba(0, 0, 0, 0.027);
    align-items: center;
    width: 100%;


}

.linkedin {
    margin-left:auto;
    margin-right:20px;
}

.github {
    margin-right: 20px
}

.tech {
    font-weight: 200;
    margin-left: 20px;
}

.icons {
    margin-left: auto;
}


