.project-title {
    
    font-weight: 200;
    text-decoration: underline;
    text-decoration-thickness: 1px;
}

.project-img {
    width: 600px;
    opacity: 100%;
}



.project-description {
    font-weight: 200;
    max-width: 600px;
}

.title-container {
    display:flex;
    max-width: 600px;


    
}

.project-links {
    font-weight: 300;
}

.project-container {
    display: flex;
    flex-direction: column;
    margin-right: 150px;
    margin-bottom: 100px;
    margin-left:0px;

    
}

.tech-div {
    display:flex;
    margin-left: 20px;

}

.link, .icon-link {
    color: black;
    text-decoration: none;
}
@media (max-width: 912px) {
    .project-img {
        min-width:380px;
        width:100%;
    }
}

@media (max-width: 600px) {
    .title-container{
        flex-direction: column;
    }
    .project-title {
        left:0;
    }
    .tech {
        margin-left:0px;
    }
    .tech-div {
        margin-left: 0px;
    }
}