
.main-container {
    display:flex;
    position: relative;
    flex-direction: column;
    padding: 20px;

}

.title-main, .title-skills {
    font-weight: 300;
    font-size: 3rem;
    
}

.multi-container {
    display: flex;
    width:90%;
    flex-wrap: wrap;
    max-width: 1500px;

}

.title-skills{
    margin-top:0px;
    position: absolute;

    width:350px;
    right:0;
    text-decoration-thickness: 1px;
}

.skills-container {
    height:123px;
    width: 170px;
    display:flex;
    flex-direction: column;
    position: absolute;
    
    right:0;
    text-align: right;
    margin-top:32px;
    margin-right: 20px;
}

.skill, .skill-first {
    text-align: right;
    direction: rtl;
    font-weight: 200;
    margin-top: 0px;
    margin-bottom:0px;
    margin-right:0;
    unicode-bidi: plaintext;
}

.skill-first {
    margin-top: 112px;
}

@media (max-width: 912px) {
    .skills-container {
        display: none;
    }
}